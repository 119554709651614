import React from "react";
import { graphql } from "gatsby";
import ContactTemplate from "src/templates/contact";

export const query = graphql`
	query ContactQuery {
		page: sanityContact(_id: { regex: "/(drafts.|)contact/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 8 })
		}
	}
`;

const ContactPage = (props: any) => {
	const { location } = props;
	const content = props.data.page.content;
	return <ContactTemplate location={location} data={content} />;
};

export default ContactPage;
